import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Category, Subcategory, Procedure } from '../../../shared/interfaces/procedures/procedures';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProceduresService {

  private procedCategories$ = new BehaviorSubject<Category[] | undefined>(undefined);
  getCategories(): Observable<Category[] | undefined> {
    return this.procedCategories$.asObservable();
  }
  setCategories(categories: Category[]) {
    this.procedCategories$.next(categories);
  }

  private subCategories$ = new BehaviorSubject<Subcategory[] | undefined>(undefined);
  getSubCategories(): Observable<Subcategory[] | undefined> {
    return this.subCategories$.asObservable();
  }
  setSubCategories(subCategories: Subcategory[]) {
    this.subCategories$.next(subCategories);
  }

  indexUrl!: string;
  procedCategoryUrl!: string;
  subCategoryUrl!: string;
  procedureUrl!: string;
  storeUrl!: string;
  updateUrl!: string;
  deleteUrl!: string;

  constructor(private httpClient: HttpClient) {
    this.indexUrl = 'index';
    this.procedCategoryUrl = 'proced-category';
    this.subCategoryUrl = 'proced-subcategory';
    this.procedureUrl = 'procedure';
    this.storeUrl = 'store';
    this.updateUrl = 'update';
    this.deleteUrl = 'delete';
  }

  getProcedCategories() {
    return this.httpClient.get(`${environment.apiUrl}${this.procedCategoryUrl}/${this.indexUrl}`);
  }

  getProcedSubCategories() {
    return this.httpClient.get(`${environment.apiUrl}${this.subCategoryUrl}/${this.indexUrl}`);
  }

  getProcedures() {
    return this.httpClient.get(`${environment.apiUrl}${this.procedureUrl}/${this.indexUrl}`);
  }

  storeProcedure(procedure: Procedure) {
    return this.httpClient.post(`${environment.apiUrl}${this.procedureUrl}/${this.storeUrl}`, procedure);
  }

  updateProcedure(procedure: Procedure, procedureId: number) {
    return this.httpClient.put(`${environment.apiUrl}${this.procedureUrl}/${this.updateUrl}/${procedureId}`, procedure)
  }

  deleteProcedure(procedureId: number) {
    return this.httpClient.delete(`${environment.apiUrl}${this.procedureUrl}/${this.deleteUrl}/${procedureId}`);
  }

}
