import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Patient } from '../../../shared/interfaces/patient/patient';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  private currentPatientsList$ = new BehaviorSubject<Patient[] | undefined>(undefined);
  getCurrentPatientsList(): Observable<Patient[] | undefined> {
    return this.currentPatientsList$.asObservable();
  }
  setCurrentPatientsList(patientsList: Patient[]) {
    this.currentPatientsList$.next(patientsList);
  }

  private currentPatient$ = new BehaviorSubject<Patient | undefined>(undefined);
  getCurrentPatient(): Observable<Patient | undefined> {
    return this.currentPatient$.asObservable();
  }
  setCurrentPatient(patient: Patient) {
    this.currentPatient$.next(patient);
  }

  patientUrl!: string;
  indexUrl!: string;
  showUrl!: string;
  storeUrl!: string;
  updateUrl!: string;
  storeImagesUrl!: string;
  updateProfilePhotoUrl!: string;
  imagesUrl!: string;
  patientConsentUrl!: string;
  byPatientUrl!: string;
  treatmentPlanUrl!: string;
  generalDiagnosisUrl!: string;
  piecesUrl!: string;
  allUrl!: string;

  constructor(private httpClient: HttpClient) {
    this.patientUrl = 'patient';
    this.indexUrl = 'index';
    this.showUrl = 'show';
    this.storeUrl = 'store';
    this.updateUrl = 'update';
    this.storeImagesUrl = 'store-images';
    this.updateProfilePhotoUrl = 'update_profile_photo';
    this.imagesUrl = 'images';
    this.patientConsentUrl = 'patient-consent';
    this.byPatientUrl = 'by-patient';
    this.treatmentPlanUrl = 'treatment-plan';
    this.generalDiagnosisUrl = 'general-diagnosis';
    this.piecesUrl = 'pieces';
    this.allUrl = 'all';
  }

  getPatientsByClinic(clinicId: number) {
    return this.httpClient.get(`${environment.apiUrl}${this.patientUrl}/${this.indexUrl}/${clinicId}`);
  }

  showPatient(patientId: number) {
    return this.httpClient.get(`${environment.apiUrl}${this.patientUrl}/${this.showUrl}/${patientId}`);
  }

  updatePatientAvatar(image: File, patientId: number) {
    const formData = new FormData();
    formData.append('profile_photo', image);
    return this.httpClient.post(`${environment.apiUrl}${this.patientUrl}/${this.updateProfilePhotoUrl}/${patientId}`, formData);
  }

  getPatientConsents(patientId: number) {
    return this.httpClient.get(`${environment.apiUrl}${this.patientConsentUrl}/${this.byPatientUrl}/${patientId}`);
  }

  storePatient(patient: any) {
    return this.httpClient.post(`${environment.apiUrl}${this.patientUrl}/${this.storeUrl}`, patient);
  }

  editPatient(patient: any, patientId: number) {
    return this.httpClient.put(`${environment.apiUrl}${this.patientUrl}/${this.updateUrl}/${patientId}`, patient);
  }

  addPatientConsent(consentId: string, consent: File, patientId: number) {
    const formData = new FormData();
    formData.append('file', consent);
    formData.append('consent_id', consentId);
    return this.httpClient.post(`${environment.apiUrl}${this.patientConsentUrl}/${this.storeUrl}/${patientId}`, formData);
  }

  getPatientImages(patientId: number) {
    return this.httpClient.get(`${environment.apiUrl}${this.patientUrl}/${this.imagesUrl}/${patientId}`);
  }

  addPatientImage(image: File, patientId: number) {
    const formData = new FormData();
    formData.append('image', image);
    return this.httpClient.post(`${environment.apiUrl}${this.patientUrl}/${this.storeImagesUrl}/${patientId}`, formData);
  }

  getPatientTreatmentPlan(patientId: number) {
    return this.httpClient.get(`${environment.apiUrl}${this.treatmentPlanUrl}/${this.patientUrl}/${patientId}`);
  }

  getPatientLabOrders(patientId: number) {
    return this.httpClient.get(`${environment.apiUrl}${this.treatmentPlanUrl}/${this.patientUrl}/${patientId}`);
  }

  getPatientGeneralDiagnosis(patientId: number) {
    return this.httpClient.get(`${environment.apiUrl}${this.generalDiagnosisUrl}/${this.byPatientUrl}/${patientId}`);
  }

  getPieces() {
    return this.httpClient.get(`${environment.apiUrl}${this.piecesUrl}/${this.allUrl}`)
  }

}
