import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Specialist } from '../../../shared/interfaces/specialist/specialist';

@Injectable({
  providedIn: 'root'
})
export class SpecialistService {

  private specialists$ = new BehaviorSubject<Specialist[] | undefined>(undefined);
  getCurrentSpecialists(): Observable<Specialist[] | undefined> {
    return this.specialists$.asObservable();
  }
  setCurrentSpecialists(specialists: Specialist[]) {
    this.specialists$.next(specialists);
  }

  indexUrl!: string;
  specialistUrl!: string;

  constructor(private httpClient: HttpClient) {
    this.indexUrl = 'index';
    this.specialistUrl = 'specialist';
  }

  getSpecialists() {
    return this.httpClient.get(`${environment.apiUrl}${this.specialistUrl}/${this.indexUrl}`);
  }

}
