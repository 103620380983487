import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LabOrderService {

  labOrderUrl!: string;
  patientUrl!: string;
  clinicUrl!: string;
  storeUrl!: string;

  constructor(private httpClient: HttpClient) {
    this.labOrderUrl = 'lab-order';
    this.patientUrl = 'patient';
    this.clinicUrl = 'clinic';
    this.storeUrl = 'store';
  }

  getByPatient(patientId: number) {
    return this.httpClient.get(`${environment.apiUrl}${this.labOrderUrl}/${this.patientUrl}/${patientId}`);
  }

  getByClinic(clinicId: number) {
    return this.httpClient.get(`${environment.apiUrl}${this.labOrderUrl}/${this.clinicUrl}/${clinicId}`);
  }

  create(labOrder: any) {
    return this.httpClient.post(`${environment.apiUrl}${this.labOrderUrl}/${this.storeUrl}`, labOrder);
  }


}
