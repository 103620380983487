import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MedicService {

  medicUrl!: string;
  byClinicUrl!: string;

  constructor(private httpClient: HttpClient) {
    this.medicUrl = 'medic';
    this.byClinicUrl = 'by-clinic';
  }

  getMedicsByClinic(clinicId: number) {
    return this.httpClient.get(`${environment.apiUrl}${this.medicUrl}/${this.byClinicUrl}/${clinicId}`);
  }
}
