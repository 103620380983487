import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LabsService {

  labsUrl!: string;
  allUrl!: string;

  constructor(private httpClient: HttpClient) {
    this.labsUrl = 'labs';
    this.allUrl = 'all';
  }

  getAll() {
    return this.httpClient.get(`${environment.apiUrl}${this.labsUrl}/${this.allUrl}`);
  }

}
